<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Electrical Properties" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The purpose of determining the electrical properties of explosives
            is to provide the input necessary for modeling of the electrical
            circuit formed by the system or sub system. This is of special
            interest in situations where electrical charges may accumulate in
            the system due to process parameters. Hazardous conditions may
            develop if electrostatic charges are allowed to accumulate
            unchecked, or through dynamic current flow.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Triboelectric Charging and Sliding Charge Generation"
        />
        <Paragraph
          ><p>
            One means of inducing electrostatic charge on a material or system
            is by triboelectrification. This may occur in many ways, for
            example, by mere contact and separation of two dissimilar materials
            or as ingredients feed through a tube or fall through the air. These
            test measure the amount of charge generated by the operation in
            question. These tests are generally tailored to the process or
            situation of most interest.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Airvey Charge Generation" />
        <Paragraph
          ><p>
            Another form of triboelectrification of special interest to any one
            considering airveying of explosives or explosive dusts.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Dielectric Breakdown" />
        <Paragraph
          ><p>
            This test is used to rank propellant sensitivity to dielectric
            breakdown due to electrostatic discharge phenomena. It is also used
            in electrical modeling of propellant and explosives and their
            systems. Voltages up to 60 Kilo-volts are used. Both the sudden
            discharge of electrons through an explosive and the build-up of
            charge can lead to initiation of the material and effect the safety
            of the system.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="French (ESD) Test" />
        <Paragraph
          ><p>
            This is a “go”, “no-go” series of tests to determine the
            susceptibility of a propellant to ignition by electrostatic
            discharge/dielectric breakdown in a typical processing operation.
            Three 90 mm x 100 mm cylindrical samples are prepared and subjected
            to 30 discharges each of a predetermined energy and voltage. Tests
            at operational limits as well as ambient temperature are typically
            recommended. The propellant passes the test if none of the 90 trials
            in a series results in bursts of flame, explosions, ignition or
            fragmentation of the sample.
          </p>
        </Paragraph>
      </div>

      <div class="w-full md:w-1/4"></div>

      <div class="w-full md:w-1/2 mb-6">
        <TitleComponent
          title="Volume and Surface Resistivity, Dielectric Constant"
        />

        <Paragraph
          ><p>
            To determine the electrical properties of explosives and
            non-explosives, SMS performs a volume and/or surface resistivity
            test, and a dielectric constant test. The ability of a material to
            conduct, store and dissipate charge can then be evaluated for the
            specific conditions of processing or use for the material. These
            values are most often used to model the process or condition so as
            to determine whether hazardous levels of charge and/or voltage can
            be produced or stored in the system. This is also a basis for tests
            of the degree of electrical shielding provided by materials in the
            system or process.<br />
            Reference: JANNAF Propulsion Systems Hazards, ESD Subcommittee
            Report, CPIA….
          </p>
        </Paragraph>
      </div>

      <div class="w-full md:w-1/4"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Electrical Properties of Explosives",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can determine the electrical properties of explosives to identify the electrical input required for initiation. Electrical property tests include Triboelectric Charging and Sliding Charge Generation, Airvey Charge Generation, Dielectric Breakdown, and the French (ESD) Test."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
