<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Thermal and Compatibility Tests" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The purpose of thermal sensitivity testing is to determine the
            effects of time and temperature on explosives. A thermal sensitivity
            test is also conducted as a part of the DOT Classification (See DOT
            Classification).
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Differential Scanning Calorimeter (DSC)" />
        <Paragraph
          ><p>
            A DSC can be used to determine the exotherms and endotherms of an
            explosive. This test identifies critical temperatures where a phase
            change or run-away reaction may occur.
          </p>
          <p>
            A DSC compatibility test can be used to determine the ability of a
            material to coexist in intimate contact with an explosive without
            adverse reaction, for an acceptable period of time. This test
            measures heat increase or heat loss. It is used when looking for an
            exothermic or endothermic reaction. This test may be used for
            composite explosives.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Fisher-Johns Auto-Ignition (FJAI)" />
        <Paragraph
          ><p>
            The Fisher-Johns Auto-ignition Test is performed to determine the
            thermal/time conditions under which an explosive of a specified
            sample will ignite. Auto ignition is investigated in two ways: (a)
            Heat the sample at a constant rate (normally 15°C/min.). (b) Time
            the sample maintained at a constant, elevated temperature until
            ignition occurs (normally the test material is held at 120°C for up
            to one hour).
          </p>
          <p>
            During heating, any physical changes of the sample such as melting,
            fuming, or smoking, etc., is recorded noting the temperature at
            which these changes occur. During a normal auto-ignition study,
            three tests are conducted and an average auto-ignition temperature
            is reported. During a normal auto-ignition study, three tests are
            conducted using method (a). For a screening study, one test is
            sufficient.<br />
            Reference: Standard thermal analysis methodology and theory.
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Henkin Bath" />
        <Paragraph
          ><p>
            The Henkin bath test is used to determine the thermal stability of
            explosives by isothermally testing samples in a heated metal bath. A
            sample of explosive is weighed (50 mg) and placed in a No. 8
            blasting cap shell. The cap is then sealed (aluminum shell). Testing
            is done on the sealed test samples at various temperatures. The test
            measures the time to explosion. A graph is made from final data
            showing time vs. temperature to determine the thermal stability of
            the explosive sample being tested (cook off time, and reaction
            rates). This differential thermal analysis combined with other tests
            (impact) may be used to determine proper explosive handling
            techniques.
          </p>
          <p>
            This equipment can also be used to determine long term compatibility
            between a test sample (e.g. paints, adhesives, ingredients etc.) and
            an explosive.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Modified Taliani" />
        <Paragraph
          ><p>
            The modified Taliani test is used to determine the ability of a
            material to coexist in intimate contact with an explosive without
            adverse reaction, for an acceptable period of time. The usual
            conditions for the test are 93 °C (200 °F) for 23 hours in a closed
            inert atmosphere, as opposed to 5 hours in the standard Taliani
            test. This test uses a measuring device to measure pressure and is
            valid for about 70% of compatibility tests required. However it is
            not valid for the following:
          </p>
          <ul>
            <li>Volatile solvents</li>
            <li>Gases</li>
            <li>Materials producing heat only (no gaseous products)</li>
            <li>Some composite explosives</li>
          </ul>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent
          title="Simulated-Bulk Auto-Ignition Temperature (SBAT)"
        />
        <Paragraph
          ><p>
            In the past, companies have commonly used the Henkin
            Time-to-Explosion test to calculate a sample’s Critical Temperature
            and its dependency on the sample’s size. However, the Henkin
            Time-to-Explosion test is an older test whose techniques are more
            archaic in comparison to safer, more modern techniques.
          </p>
          <p>
            A modern technique for calculating the critical temperature consists
            of using a Simulated Bulk Auto-Ignition Temperature (SBAT)
            apparatus. This apparatus utilizes a 3-5 gram sample and a
            heavily-insulated sample holder to accurately simulate the ignition
            behavior of the bulk material in response to temperature.
          </p>
          <p>
            The SBAT critical temperature is advantageous because it is
            sample-size independent; the critical temperature for the bulk
            material is the lowest, or worst-case critical temperature
            determined by the testing. Hence, despite sample configuration,
            provided the temperature of the sample is below the SBAT’s critical
            temperature and that the material is non-autocatalytic, thermal
            ignition of the material will not occur.
          </p>
          <p>
            Another advantage of the SBAT test is that it can monitor the
            temperature of the sample, relative to the surrounding heater
            temperature. This ensures that the sample’s heat production can be
            detected and measured well before a run-away reaction proceeds to
            ignition.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Taliani Stability" />
        <Paragraph
          ><p>
            The Taliani stability test is used as a standard method for the
            evaluation of the relative stability of explosive of known
            composition by subjection to a specified temperature and atmosphere.
            The test determines stability by measuring the pressure of gasses
            evolved from a sample under conditions of constant temperature and
            volume. The test is limited to compositions of known or predictable
            stability, which normally will not undergo rapid reaction or
            decomposition when exposed to temperatures of 110 +/- 0.1 °C for
            short periods of time. This test cannot be run on samples that
            contain large quantities of volatiles. A plot of time versus
            pressure makes up the stability curve.
          </p>
          <p>
            A dry sample is placed in a tube at 100°C. The tube is evacuated.
            The pressure above the sample is measured. The increase in pressure
            is a measure of the rate of decomposition of the sample.
          </p>
        </Paragraph>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Thermal Stability" />
        <Paragraph
          ><p>
            This test is used to determine the reaction of samples when
            subjected to elevated temperatures for a determined period of time.
            The test uses an oven to verify handling, transportation, and
            storage requirements. The 50 g sample is placed in a constant
            temperature (explosion-proof) oven at 75 °C for a period of
            forty-eight hours. The temperature is continuously monitored and
            recorded. At the completion of the test, the sample is examined for
            discoloration, weight loss, and dimensional change for evidence of
            decomposition. This test is part of a series of tests used for
            establishing hazard classification.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Vacuum Stability" />
        <Paragraph
          ><p>
            Although usually performed at 100 °C, other temperatures can be used
            in the vacuum stability test. The sample is placed under 5 mm Hg at
            constant temperature for 40 – 48 hours. The volume of gas liberated
            is calculated. The test is discontinued if 11+milliliters of gas is
            evolved. Rate data may be obtained if gas released is measured at
            intervals. In the vacuum stability test, increased reactivity is
            indicated by the mixture evolving more than five milliliters of gas
            over the sum of the amount of gas produced by the ingredients tested
            separately.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Thermal Sensitivity Compatibility Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has the capability to perform numerous thermal sensitivity and capability tests, including DSC testing, Fisher-John Auto-Ignition, Henkin Bath, Modified Taliani, and Simulated-Bulk Auto-Ignition Temperature."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
